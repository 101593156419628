<template>
  <a-dims-search-bar hide-search>
    <template #search-button>
      <button class="focus:outline-none">
        <transition name="fade" mode="out-in">
          <a-loader class="h-6 w-6" color="text-blue-500" v-if="loading" />
          <i ref="checkIcon" v-else class="icon-checkmark text-primary font-bold text-xl p-4 inline-block -mx-4 select-none" />
        </transition>
      </button>
    </template>

    <div class="flex uppercase justify-between p-4 space-x-2 -mx-4">
      <button @click="$router.back" class="space-x-2 text-gray-700 uppercase flex items-center">
        <i class="icon-arrowLeft text-gray-600" />
        <span class="font-bold">Editar Dirección</span>
      </button>
    </div>
  </a-dims-search-bar>

  <div class="block items-start px-4 pt-6" style="margin-top: 56px;">
    <label for="direction" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Dirección</label>
    <input type="text" v-model="direction" class="border-none underlined py-2 text-lg inline-block align-middle" style="width: calc(100% - 120px);" id="direction">
  </div>

  <div class="block items-start px-4 pt-6">
    <label for="city" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Ciudad</label>
    <input type="text" v-model="city" class="border-none underlined py-2 text-lg inline-block align-middle" style="width: calc(100% - 120px);" id="city">
  </div>

  <div class="block items-start px-4 pt-6 text-left">
    <label for="state" class="font-bold text-left text-lg inline-block align-middle" style="width: 120px;">Est./Provincia</label>
<!--    <input type="text" v-model="state" class="border-none underlined py-2 text-lg inline-block align-middle" style="width: calc(100% - 120px);" id="state">-->
    <select id="companionSelectorSS" class="flex-1 border p-1 rounded px-2" v-model="state">
      <option value="madrid">Madrid</option>
      <option value="barcelona">Barcelona</option>
      <option value="sevilla">Sevilla</option>
      <option value="bilbao">Bilbao</option>
      <option value="valencia">Valencia</option>
      <option value="málaga">Málaga</option>
    </select>
  </div>

  <div class="block items-start px-4 pt-6">
    <label for="country" class="text-gray font-bold text-left text-lg inline-block align-middle" style="width: 120px;">País</label>
    <input type="text" v-model="country" disabled class="pl-2 border-none underlined py-2 text-lg inline-block align-middle" style="width: calc(100% - 120px);" id="country">
  </div>

  <div class="flex items-center px-4 content-between mt-4" style="bottom: 55px;">
    <div class="flex-grow"></div>
    <div class="text-right inline-block" style="width: 120px;">
      <button @click="update()" class="bg-primary rounded-3xl text-white w-24 py-2 px-4 flex-1">
        <a-loader v-if="loading" class="inline-block w-auto h-5 w-5" :color="'text-gray'"/>
        <span v-else>Guardar</span>
      </button>
    </div>
  </div>

  <a-alert :show="show.success" hide-cancel @confirm="$router.go()">
    <div class="flex space-x-4 items-start">
      <i class="icon-checkmark p-4 text-xl bg-green-500 rounded-full text-white shadow-lg" />
      <div class="flex flex-col">
        <h1 class="text-black text-xl">¡Guardado!</h1>
        <h1 class="text-xl mt-2">Tus datos han sido actualizados correctamente</h1>
      </div>
    </div>
  </a-alert>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'EditProfileDirection',
  data () {
    return {
      loading: false,
      values: {},
      direction: '',
      city: '',
      state: '',
      country: '',
      zip_code: '',
      show: {
        success: false
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      current: ({ session }) => session.user
    })
  },
  methods: {
    update () {
      this.loading = !this.loading
      // this.user.phone = '+34' + this.auxPhone
      this.$repository.foodies
        .update(this.$route.params.id, { address_line: this.direction, city: this.city, state: this.state })
        .then(({ data: foodie }) => {
          console.log(foodie)
          // this.setUser({ ...this.current, foodie })
          this.show.success = true
        })
        .finally(() => {
          this.loading = !this.loading
          // this.show.success = true
        })
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      this.$repository.foodies
        .show(this.$route.params.id)
        .then(({ data }) => {
          this.direction = data.address_line
          this.city = data.city
          this.state = data.state.toLowerCase()
          this.country = 'España'
        })
        .catch((error) => (console.log(error)))
    }
  }
}
</script>

<style scoped>

</style>
